.myColDiv{
    flex: 0 0 19.666667% ;
    max-width: 25.666667% ;   
}

.mySelectBox {
  border: 2px solid #0047ff;
  border-radius: 5px;
  min-width: 142px;
  cursor: pointer;
}
select:focus{
  border-color: #0047ff !important;
  color: #0047ff !important;
}

.myNftSearchBox input {
  border-radius: 5px;
  border: 2px solid #0047ff;
  padding-top: 22px;
  padding-bottom: 22px;
  background-color: transparent !important;
  color: #fff;
  min-width: 142px;
}
.myNftSearchBox input:focus {
  outline: none;
  /* border: none; */
  border-radius: 5px;
  border: none;
}
.myNftSearchBox input:focus-visible {
  outline: none;
  border: none;
}


@media only screen and (max-width: 1023px) {
    /* For tablets: */
    .myColDiv {
      flex: 0 0 19%;
      min-width: 33%;
    }
  }
  
  @media only screen and (max-width: 767px) {
    /* For tablets: */
    .myColDiv {
      flex: 0 0 19%;
      min-width: 50%;
      display: flex ;
      justify-content: center;
    }

  }
  @media only screen and (max-width: 425px) {
    /* For tablets: */
    .myColDiv {
      flex: 0 0 19%;
      min-width: 100%;
      display: flex ;
      justify-content: center;
    }
  }